<template>
  <button
    v-if="styling.allowOfflineMode"
    :style="syncBtnStyle"
    class="offline-submission-sync-button"
    @click="emits('sync-submissions')"
  >
    <i
      v-if="syncing"
      class="fa-solid fa-circle-notch fa-spin"
      aria-hidden="true"
    />

    <span v-else-if="offlineSubmissionCount > 0">{{
      offlineSubmissionCount
    }}</span>

    <svg
      v-else
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="18"
      height="18"
      viewBox="0 0 50 50"
      :fill="styling.buttonTextColor"
    >
      <path
        d="M 41.9375 8.625 C 41.273438 8.648438 40.664063 9 40.3125 9.5625 L 21.5 38.34375 L 9.3125 27.8125 C 8.789063 27.269531 8.003906 27.066406 7.28125 27.292969 C 6.5625 27.515625 6.027344 28.125 5.902344 28.867188 C 5.777344 29.613281 6.078125 30.363281 6.6875 30.8125 L 20.625 42.875 C 21.0625 43.246094 21.640625 43.410156 22.207031 43.328125 C 22.777344 43.242188 23.28125 42.917969 23.59375 42.4375 L 43.6875 11.75 C 44.117188 11.121094 44.152344 10.308594 43.78125 9.644531 C 43.410156 8.984375 42.695313 8.589844 41.9375 8.625 Z"
      ></path>
    </svg>
  </button>
</template>

<script setup>
import { computed } from "vue";

// Props
const props = defineProps({
  offlineSubmissionCount: {
    type: Number,
    required: true,
  },
  styling: {
    type: Object,
    required: true,
  },
  syncing: {
    type: Boolean,
    required: true,
  },
});

// emits
const emits = defineEmits(["sync-submissions"]);

// Computed
const syncBtnStyle = computed(() => {
  return {
    "background-color": props.styling.buttonColor || "#00A3FF",
    "border-color": props.styling.buttonColor || "#00A3FF",
    color: props.styling.buttonTextColor || "white",
  };
});
</script>

<style scoped>
.offline-submission-sync-button {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid;
  padding: 11px 12px;
  outline: none;
  cursor: pointer;
}

.offline-submission-sync-button span {
  padding: 0 8px;
}
</style>
