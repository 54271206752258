<template>
  <div
    v-if="showBanner && !isIos"
    ref="installBanner"
    class="install-banner w-100"
    :style="bannerStyle"
    hidden
  >
    <div class="d-flex align-items-center">
      <span class="close-banner" @click="hideBanner">
        <i
          class="fa fa-times"
          aria-hidden="true"
          :style="`color: ${styling.buttonTextColor}`"
        />
      </span>
      <span>Formeter</span>
    </div>
    <button class="install-app" :style="installBtnStyle" @click="handleInstall">
      Install App
    </button>
  </div>

  <div
    v-if="showBanner && iosBanner"
    class="install-banner w-100 d-flex align-items-center justify-content-between"
    :style="bannerStyle"
  >
    <p>
      To install this webapp on your device tap
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 0 24 24"
        width="18"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path
          :fill="styling.buttonTextColor"
          d="M16 5l-1.42 1.42-1.59-1.59V16h-1.98V4.83L9.42 6.42 8 5l4-4 4 4zm4 5v11c0 1.1-.9 2-2 2H6c-1.11 0-2-.9-2-2V10c0-1.11.89-2 2-2h3v2H6v11h12V10h-3V8h3c1.1 0 2 .89 2 2z"
        />
      </svg>
      and then Add to homescreen
    </p>
    <span class="close-banner" @click="hideBanner">
      <i
        class="fa fa-times"
        aria-hidden="true"
        :style="`color: ${styling.buttonTextColor}`"
      />
    </span>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";

// Props
const props = defineProps({
  styling: {
    type: Object,
    required: true,
  },
});

// State
const installBanner = ref(null);
const showBanner = ref(true);
const installPrompt = ref(null);

// Computed
const bannerStyle = computed(() => {
  return {
    "background-color": props.styling.buttonColor,
    color: props.styling.buttonTextColor,
  };
});

const installBtnStyle = computed(() => {
  return {
    "background-color": props.styling.buttonTextColor,
    "border-color": props.styling.buttonTextColor,
    color: props.styling.buttonColor,
  };
});

const isIos = computed(() => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
});

const isInStandaloneMode = computed(
  () => "standalone" in window.navigator && window.navigator.standalone,
);

const iosBanner = computed(() => isIos.value && !isInStandaloneMode.value);

// Mounted
onMounted(() => {
  window.addEventListener("beforeinstallprompt", (event) => {
    if (showBanner.value && !isIos.value) {
      event.preventDefault();
      installPrompt.value = event;
      installBanner.value.removeAttribute("hidden");
    }
  });
});

// Methods
async function handleInstall() {
  if (!installPrompt.value) {
    return;
  }
  await installPrompt.value.prompt();
  hideBanner();
}

function hideBanner() {
  installPrompt.value = null;
  showBanner.value = false;
  installBanner.value?.setAttribute("hidden", "");
}
</script>

<style scoped>
.install-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 18px;
  gap: 10px;
  background-color: var(--placeholder-text-color);
}

.install-banner span {
  font-weight: 500;
}

.install-banner p {
  margin: 0;
}

.install-app {
  cursor: pointer;
  border: 1px solid;
  border-radius: 2px;
  outline: none;
  padding: 6px 16px;
  font-size: 14px;
}

.close-banner {
  cursor: pointer;
  margin-right: 15px;
}
</style>
