<template>
  <div></div>
</template>

<script>
import { addScriptToPage } from "@/helpers/utils";

export default {
  props: {
    formId: {
      type: String,
      required: true,
    },
    formUuid: {
      type: String,
      required: true,
    },
    formName: {
      type: String,
      required: true,
    },
    integration: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    addScriptToPage(
      null,
      `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq("init", ${this.integration.provider_id});
      fbq("track", "PageView");`,
    );

    // event bus
    this.emitter.on("submit", (params = {}) => {
      fbq("trackCustom", "FormesterSubmit", {
        ...params,
        form_uuid: this.formUuid,
        form_name: this.formName,
      });
    });

    this.emitter.on("firstInteraction", (params = {}) => {
      fbq("trackCustom", "FormesterFirstInteraction", {
        ...params,
        form_uuid: this.formUuid,
        form_name: this.formName,
      });
    });
  },
};
</script>
