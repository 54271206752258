import SurveyForm from "@/components/survey/index.vue";
import VueClickAway from "vue3-click-away";
import { createApp } from "vue/dist/vue.esm-bundler";
import initializeSentry from "@/utils/sentry";
import mitt from "mitt";
const emitter = mitt();

const SurveyFormShow = createApp({
  components: {
    "survey-form-view": SurveyForm,
  },
});

initializeSentry(SurveyFormShow);
SurveyFormShow.config.globalProperties.emitter = emitter;
SurveyFormShow.use(VueClickAway);
SurveyFormShow.mount("#survey-form-index");
