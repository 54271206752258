<template>
  <div class="formester">
    <OfflineSubmissionSync
      :offline-submission-count="offlineSubmissionCount"
      :styling="styling"
      :syncing="syncingSubmissions"
      @sync-submissions="handleSyncSubmission"
    />

    <FormesterForm
      v-if="!loading"
      ref="formesterForm"
      :is-iframe="isIframe"
      :action="postUrl"
      :form="form"
      :styling="styling"
      :is-preview="isPreview"
      :uuid="uuid"
      :form-id="formId"
      :prefill="prefill"
      :is-free-user="isFreeUser"
      :rules="rules"
      :variables="variables"
      :pages="pages"
      :session-id="sessionId"
      :survey-url="formDetails.surveyUrl"
      @submit="handleSubmit"
    />

    <PgLoader :loading="loading" :color="primaryColor" style="height: 100vh" />

    <TrackingIntegrations
      v-if="!isEmpty(formDetails.trackingIntegrations)"
      :form-id="formId"
      :form-uuid="formDetails.uuid"
      :form-name="formDetails.name"
      :integrations="formDetails.trackingIntegrations"
    />

    <InstallAppBanner v-if="styling.allowOfflineMode" :styling="styling" />
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import mergeWith from "lodash/mergeWith";
import merge from "lodash/merge";
import keyBy from "lodash/keyBy";
import isEmpty from "lodash/isEmpty";

import { ElNotification } from "element-plus";
import { getFormSurveyDetails } from "@/api/forms";
import { networkStatus } from "@/utils/checkNetworkStatus.js";
import FormesterAnalytics from "@/utils/formester-analytics.js";
import OfflineModeFormController from "@/controllers/pwa/offline_mode_form_controller";
import formInitial from "@/components/builder/form-initials";
import defaultStyling from "@/components/builder/default-styling";
import defaultPageStyling from "@/components/builder/default-page-styling";

// Components
import PgLoader from "@/components/shared/loading.vue";
import FormesterForm from "@/components/builder/formester-form.vue";
import TrackingIntegrations from "@/components/tracking_integrations/index.vue";
import OfflineSubmissionSync from "./offline-submission-sync.vue";
import InstallAppBanner from "./install-app-banner.vue";

// Generate Metatags
import getSiteMeta from "@/helpers/getSiteMeta.js";

// composables
import { useFormesterSession } from "@/helpers/composables/use-formester-session";

export default {
  components: {
    FormesterForm,
    PgLoader,
    TrackingIntegrations,
    OfflineSubmissionSync,
    InstallAppBanner,
  },
  provide() {
    return {
      formesterAnalytics: new FormesterAnalytics(this.sessionId, this.uuid),
    };
  },
  props: {
    formId: {
      type: String,
      required: true,
    },
    uuid: {
      type: String,
      required: true,
    },
    primaryColor: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { sessionId, clearSession } = useFormesterSession();

    return {
      sessionId,
      clearSession,
    };
  },
  data() {
    return {
      formDetails: {},
      form: [],
      styling: { ...defaultStyling },
      prefill: {},
      isFreeUser: false,
      rules: [],
      variables: [],
      pages: [],
      loading: true,
      formController: null,
      offlineSubmissionCount: 0,
      offlineSubmissionCountObserver: null,
      isOnline: true,
      syncingSubmissions: false,
      isPreview: false,
      pageStyling: cloneDeep(defaultPageStyling),
    };
  },
  computed: {
    postUrl() {
      return `/forms/${this.uuid}/submissions.json`;
    },
    isIframe() {
      return parent.location !== window.location;
    },
    formElementsMap() {
      return keyBy(formInitial, "type");
    },
    meta() {
      const metaData = {
        type: "website",
        url: `https://app.formester.com/f/${this.uuid}`,
        title: this.formDetails.name,
        description: "Fill out this form to get started",
        mainImage:
          "https://formester.com/formester-form-builder-background.png", // need to update with survey page image
        mainImageAlt: "Form builder showing drag and drop functionality", // need to update with survey page image alt
      };
      return getSiteMeta(metaData);
    },
  },
  async mounted() {
    // prefill value from query parameter
    const query = new URLSearchParams(window.location.search);

    // used for rendering survey forms as preview in embed page
    this.isPreview = query.get("preview");

    for (const [key, value] of query.entries()) {
      if (value) {
        this.prefill[key] = value;
      }
    }

    const params = { session_id: this.sessionId };
    if (this.isPreview) {
      params.preview = true;
    }

    await this.getExitstingForm(this.uuid, params);

    // Show thank-you page
    if (query.has("payment_status") && query.has("payment_id")) {
      this.handleSubmit();
    }

    // Offline form
    this.handleOfflineForm();
  },
  beforeUnmount() {
    this.offlineSubmissionCountObserver?.unsubscribe();
    networkStatus.destroy();
  },
  methods: {
    isEmpty,
    handleSubmit(e) {
      // reset session id after submission
      this.clearSession();

      const thankYou = this.pages.find((page) => page.type === "thank-you");

      if (thankYou) {
        this.styling.multiPage = false;
        this.$refs.formesterForm.resetCurrentPage();
        this.$refs.formesterForm.formSubmitted(e?.thankYouPageId);
      } else {
        try {
          // using window.parent instead of window as navigation
          // would not work in iframe
          if (e?.redirection_url) {
            window.parent.location.href = e.redirection_url;
          } else {
            window.parent.location.pathname = "/thanks";
          }
        } catch (error) {
          if (
            error.message.includes(
              "Failed to set the 'href' property on 'Location'",
            )
          ) {
            console.warn("Navigation failed:", error.message);
          } else {
            throw error;
          }
        }
      }
    },
    async getExitstingForm(id, params) {
      this.loading = true;
      try {
        const { data } = await getFormSurveyDetails(id, params);
        this.isFreeUser = data.isFreeUser;
        const customizer = (initial, resp, key) => {
          if (
            (key === "options" || key === "columns" || key === "rows") &&
            resp
          ) {
            return resp;
          }
        };
        const form = [];
        for (let el of Object.values(data.formBuilder)) {
          form.push(
            mergeWith(cloneDeep(this.formElementsMap[el.type]), el, customizer),
          );
        }

        this.formDetails = data;
        this.form = form;
        this.styling = merge(cloneDeep(this.styling), data.styling);
        this.rules = merge(cloneDeep(this.rules), data.rules);
        this.variables = merge(
          cloneDeep(this.variables),
          data.formVariablesAttributes,
        );
        this.pages = Object.values(data.formPages) || [];

        // merge pageStyling with default pageStyling
        this.pages.forEach((page) => {
          page.pageStyling = merge(
            cloneDeep(this.pageStyling),
            page.pageStyling,
          );
        });
      } catch (error) {
        console.log("Not able to get data");
      } finally {
        this.loading = false;
      }
    },
    range(start, end) {
      let res = [];
      for (let i = start; i <= end; i++) {
        res.push(i);
      }
      return res;
    },
    async handleSyncSubmission() {
      if (this.syncingSubmissions) return;

      await networkStatus.checkOnlineStatus();
      if (networkStatus.online && this.offlineSubmissionCount > 0) {
        this.syncingSubmissions = true;
        await this.formController.syncSubmissions();
        this.syncingSubmissions = false;
      }
    },
    handleOfflineForm() {
      // Return if offline mode is not allowed or form is in iframe
      if (!this.styling.allowOfflineMode || this.isIframe) return;

      // Custom class to check network status
      networkStatus.addEventListener("status", async (status) => {
        this.isOnline = status;
        if (status && !this.syncingSubmission) {
          this.syncingSubmissions = true;
          await this.formController.syncSubmissions();
          this.syncingSubmissions = false;
        }
      });

      // Observe submission count from indexed db and update offlineSubmissionCount if changed
      this.formController = new OfflineModeFormController();
      this.offlineSubmissionCountObserver = this.formController
        .observeSubmissionCount()
        .subscribe((count) => (this.offlineSubmissionCount = count));

      // Service worker inbuilt method "SyncManager" emits this event (Its in experimental stage and does not support many browsers)
      navigator.serviceWorker?.addEventListener("message", async (event) => {
        if (event.data.type === "backgroundSync" && !this.syncingSubmission) {
          this.syncingSubmissions = true;
          await this.formController.syncSubmissions();
          this.syncingSubmissions = false;
        }
      });

      window.addEventListener("message", async (event) => {
        if (event.data.type === "synced") {
          ElNotification({
            message: "Submissions synced successfully",
            type: "success",
            position: "bottom-right",
          });
        }
      });
    },
  },
  metaInfo() {
    return {
      meta: [
        ...(this.meta || []),
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          name: "robots",
          content: "index, follow",
        },
        { name: "format-detection", content: "telephone=no" },
      ],
      link: [
        { rel: "icon", type: "image/x-icon", href: "/favicon.ico" },
        {
          hid: "canonical",
          rel: "canonical",
          href: `https://app.formester.com/f/${this.uuid}`,
        },
      ],
    };
  },
};
</script>

<style>
@import "@/assets/styles/formester-form.css";

:root {
  --star-color: "";
  --scale-background-color: "";
  --placeholder-text-color: "";
}

.non-editing {
  padding: 10px 16px;
  border-radius: 5px;
  position: relative;
  box-sizing: border-box;
}
.non-editing.child-component {
  padding: 6px 8px;
}

.left-right {
  display: flex;
}

.left-right > label {
  width: 200px;
  flex-shrink: 0;
}

.formester {
  width: 100%;
}

.survey-form-erb {
  height: auto;
}

.formester-required::after {
  color: #f55753;
  content: " *";
  font-family: arial;
  font-size: 15px;
}

.spinner-container {
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spin {
  width: 40px;
  height: 40px;
}

@media only screen and (max-width: 768px) {
  .non-editing {
    padding: 6px 12px !important;
  }
}
</style>
