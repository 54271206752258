import { post } from "../api/_utils";

export default class FormesterAnalytics {
  constructor(session, formUUID) {
    this.session = session;
    this.formUUID = formUUID;

    this.timeSpent = {};
    this.startTime = 0;

    this.currentPage = null;

    this.handleFocusIn = this.handleFocusIn.bind(this);
    this.handleFocusOut = this.handleFocusOut.bind(this);
    this.trackPageView = this.trackPageView.bind(this);
    // NOTE: we don't want to track beacons for now.
    // need more clarity from product on tracking and showing and then we'll renable it.
    // this.beaconId = setInterval(() => {
    //   this.sendBeacon();
    // }, 5000);
  }

  // we can probably also see if we want to use action cable for this?
  // this will make api call to your analytics endpoint at regular interval
  // sending data to backend
  async sendBeacon() {
    if (Object.keys(this.timeSpent).length === 0) return; // No data to send
    await post(
      `/forms/${this.formUUID}/analytics/${this.session}/track.json`,
      this.timeSpent,
    );
  }

  stopBeacon() {
    clearInterval(this.beaconId);
  }

  handleFocusIn(e) {
    this.startTime = new Date().getTime();
  }

  handleFocusOut(e) {
    const fieldId = e.target.getAttribute("data-uuid");
    // trackTime(fieldId);
    // now we are going to work on drop offs
    // which means we want to track which is the last question the user has seen
    // trackDropOff(fieldId);
  }

  // NOTE: dropoff makes sense for multi page form with one question on each page.
  // what do we do about the other kind of forms?
  async trackPageView(pageId) {
    // create an object with all the field ids in right order
    // const visitTracker = { page-id1: true, page-id2: false, page-id3: false }
    // whenever a field is SEEN on the page we trigger this method so that visit becomes true
    this.currentPage = pageId;
    // this.pageVisits[pageId] = true;

    try {
      await post(
        `/forms/${this.formUUID}/analytics/${this.session}/track_page_view.json`,
        { currentPageId: this.currentPage },
      );
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Tracks the time spent on a given field
   * Does not work with radio and checkboxes
   * @param {uuid} fieldId
   */
  // this does not work for non input and textarea component
  // time spend outside of a field is not tracked
  trackTime(fieldId) {
    const currentTime = new Date().getTime();
    const timeSpent = currentTime - this.startTime; // Calculating time spent on field
    if (this.timeSpent[fieldId]) {
      this.timeSpent[fieldId] = this.timeSpent[fieldId] + timeSpent;
    } else {
      this.timeSpent[fieldId] = timeSpent;
    }
  }
}
