import { computed } from "vue";
import { v4 as uuid } from "uuid";

export function useFormesterSession() {
  // Create or retrieve session id from local storage
  const sessionId = computed(() => {
    let sessionId;

    try {
      sessionId = localStorage.getItem("sessionId");
      // Has access to local storage
      if (!sessionId) {
        sessionId = uuid();
        localStorage.setItem("sessionId", sessionId);
      }
    } catch (e) {
      // Doesn't have access to local storage
      sessionId = uuid();
    }

    return sessionId;
  });

  // Clear session id from local storage
  const clearSession = () => {
    try {
      localStorage.removeItem("sessionId");
    } catch (e) {
      // Doesn't have access to local storage so no need to clear sessionId
    }
  };

  return { sessionId, clearSession };
}
